export default function Panel({ panel, onClose, ...props }) {
  const { state, close } = usePanel()
  return (
    <C.Modal
      visible={ state[panel] }
      close={ () => {
        if (onClose) {
          const closeAble = onClose()
          if (closeAble === false) return
        }
        close(panel)
      } }
      { ...props }
    />
  )
}
